<template>
  <ul ref="ul" class="ul list-style-none ul w-100j">
    <li v-for="(pp,i) in pickupPoints"
        :key="i"
        class="pickup-point-list-item cl-red-hover bg-cl-white-smoke pl10 mb5"
        :ref="`pickup-point-list-item-${i}`"
        @click="$emit('pickup-point-select',pp)"
    >
      <div class="flex row">
        <div class="col-xs-11">
          <div class="pb2 w-100 ">
            <div class="pickup-point-title-wrapper flex">
              <span class="pickup-point-title">{{ `${pp.partnerName} - ${pp.street}` }}</span>
            </div>
            <div v-if="pp !== selectedPickupPoint">
              <div class=" color-silver">
                <span>{{ formatAddress(pp) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showDropdown" class="col-xs-1">
          <arrow-icon stroke="black" :direction="selectedPickupPoint !== pp ? 'down': 'up'" />
        </div>
        <div class="ml10" v-if="selectedPickupPoint === pp">
          <pickup-point-info :show-title="false" :pickup-point="pp" />
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import PickupPointInfo from './PickupPointInfo';
import ArrowIcon from '../ArrowIcon';

export default {
  name: 'PickupPointList',
  components: {
    ArrowIcon,
    PickupPointInfo
  },
  props: {
    showDropdown: {
      type: Boolean,
      default: true
    },
    pickupPoints: {
      type: Array,
      required: true
    },
    selectedPickupPoint: {
      default: undefined
    }
  },
  mounted () {
    this.$emit('update')
  },
  methods: {
    formatAddress (pickupPoint) {
      return `${pickupPoint.street} ${pickupPoint.house_number}, ${pickupPoint.postal_code} ${pickupPoint.city}`;
    }
  }
};

</script>

<style lang="scss" scoped>

@import "~theme/css/animations/transitions";
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-silver: color(silver);
$color-anyrent: color(anyrent);

.ul {
  width: 100%;
  margin-block-start: 0px;
  padding-inline-start: 0px;
}

.pickup-point-list-item:hover {
  color: $color-anyrent;
}

.color-silver {
  color: $color-silver;
}

.flex-gap-10 {
  gap: 10px
}

.cross-button {
  margin-right: -8px;
}

.list-style-none {
  list-style: none
}

.justify-content-right {
  justify-content: right;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.pickup-point-list {
  margin-top: -15px;
  padding: 10px;
}

.pickup-point-list-item {
  padding: 10px 10px 10px 20px;
  cursor: pointer;
}

.cl-red-hover:hover {
  color: $color-anyrent;
}

</style>
