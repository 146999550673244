<template>
  <div id="category">
    <chat-bot-icon />
    <pickup-point-modal-window @close="setShowPickupPoints(false)"
                               :header="$t('Selected pickup points')"
                               v-if="showPickupPoints && getPickupPoints"
    >
      <pickup-point-picker

        @filter="applyPickupPointPickerFilters"
        :preselected-partner-filters="getPreselectedPartnerFilters()"
        :pickup-points="getPickupPoints"
      />
    </pickup-point-modal-window>

    <h1 class="h1" v-if="getCurrentCategory.meta_title || getCurrentCategory.name">
      {{ getCurrentCategory.meta_title || getCurrentCategory.name }}
    </h1>
    <div class="bg-cl-primary search-bar-fixed" :class="{'search-bar-visible':searchBarVisible}">
      <div class="container pb5">
        <div class="row m0 px15 pb15">
          <div class="col-md-12 start-xs">
            <div ref="search-bar-wrapper">
              <search-bar
                @partnerInfoClick="setShowPickupPoints(true)"
                :subfilters="true"
                :current-category="getCurrentCategory"
                :categoryList="categoryList"
                :filters="getAvailableFilters"
                :filter-on-select="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-cl-secondary">
      <div class="container pb60">
        <div class="row m0 pt15">
          <div class="col-md-12 border-box products-list" :style="{'padding-top': `${productsOffsetTop}px`}">
            <div v-if="isCategoryEmpty" class="hidden-xs">
              <h4 data-testid="noProductsInfo">
                {{ $t('No products found!') }}
              </h4>
              <p>{{ $t('Please change Your search criteria and try again.') }}</p>
            </div>
            <lazy-hydrate :trigger-hydration="!loading" v-if="isLazyHydrateEnabled">
              <product-listing :columns="defaultColumn" :products="getCategoryProducts" />
            </lazy-hydrate>
            <product-listing v-else :columns="defaultColumn" :products="getCategoryProducts" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import Sidebar from '../components/core/blocks/Category/Sidebar.vue';
import ProductListing from '../components/core/ProductListing.vue';
import Breadcrumbs from '../components/core/Breadcrumbs.vue';
import SortBy from '../components/core/SortBy.vue';
import { isServer } from '@vue-storefront/core/helpers';
import { getSearchOptionsFromRouteParams } from '@vue-storefront/core/modules/catalog-next/helpers/categoryHelpers';
import config from 'config';
import Columns from '../components/core/Columns.vue';
import ButtonFull from 'theme/components/theme/ButtonFull.vue';
import { mapGetters } from 'vuex';
import onBottomScroll from '@vue-storefront/core/mixins/onBottomScroll';
import { catalogHooksExecutors } from '@vue-storefront/core/modules/catalog-next/hooks';
import { htmlDecode } from '@vue-storefront/core/filters';
import SearchBar from 'theme/components/theme/anyrent/SearchBar/SearchBar';
import NoSSR from 'vue-no-ssr';
import { AVAILABILITY_TYPE, DATE_FROM, DATE_TO, PARTNER_TYPE } from '../../../constants/FilterVariantTypes';
import PickupPointPicker from '../components/core/blocks/PickupPoint/PickupPointPicker';
import PickupPointModalWindow from '../components/core/blocks/PickupPoint/PickupPointModalWindow';
import { selfCanonical } from '../helpers/meta/selfCanonical';
import ChatBotIcon from '../components/core/blocks/ChatBot/ChatBotIcon';
import ScrollDirection from '@vue-storefront/core/mixins/ScrollDirection';
import HOMEPAGE_QUERY from '../../../../src/themes/default/graphql/strapi/queries/homepage.gql'

const THEME_PAGE_SIZE = 50;

// checks if fixed filters values are same as the url query
const syncUrlQuery = (store, route, router) => {
  const cartItems = store.getters['cart/getCartItems'];
  const partner = cartItems.length ? {
    id: cartItems[0].rental_info?.source_id,
    name: cartItems[0].rental_info?.name
  } : null;
  const from = store.getters['cart/getTotals'].find(total => total.code === 'from');
  const to = store.getters['cart/getTotals'].find(total => total.code === 'to');
  const partnerFilter = route.query[PARTNER_TYPE];
  const dateFromFilter = route.query[DATE_FROM];
  const dateToFilter = route.query[DATE_TO];
  const query = {};

  if (partner && partner.id && (!partnerFilter || partnerFilter !== partner.id)) {
    query[PARTNER_TYPE] = partner.id;
  }
  if (from && to && (!dateFromFilter || !dateToFilter || dateFromFilter !== from.value || dateToFilter !== to.value)) {
    query[DATE_FROM] = from.value;
    query[DATE_TO] = to.value;
  }
  if (Object.keys(query).length) {
    router.replace({ path: route.path, query: { ...route.query, ...query } });
  }
};
const getFixedFilters = (store) => {
  const cartItems = store.getters['cart/getCartItems'];
  const dates = store.getters['reservations/getCurrentDates'];
  const partner = cartItems.length ? {
    id: cartItems[0].rental_info?.source_id,
    name: cartItems[0].rental_info?.name
  } : null;
  const partnerType = partner ? {
    [PARTNER_TYPE]: [{
      id: partner.id,
      type: PARTNER_TYPE,
      label: partner.name,
      single: false,
      attribute_code: PARTNER_TYPE
    }]
  } : {};
  return {
    ...partnerType,
    [AVAILABILITY_TYPE]: [{
      id: AVAILABILITY_TYPE,
      type: AVAILABILITY_TYPE,
      label: AVAILABILITY_TYPE,
      single: true,
      attribute_code: AVAILABILITY_TYPE,
      from: dates.from,
      to: dates.to
    }]
  };
};

const composeInitialPageState = async (store, route, router, forceLoad = false) => {
  try {
    const filters = getSearchOptionsFromRouteParams(route.params);
    const currentCategory = await store.dispatch('category-next/loadCategory', { filters });
    const cartItems = store.getters['cart/getCartItems'];
    let fixedFilters = {};
    if (cartItems.length) {
      fixedFilters = getFixedFilters(store);
    }

    await store.dispatch('category-next/loadCategoryProducts', {
      route,
      category: currentCategory,
      pageSize: THEME_PAGE_SIZE,
      fixedFilters
    });
    const breadCrumbsLoader = store.dispatch('category-next/loadCategoryBreadcrumbs', {
      category: currentCategory,
      currentRouteName: currentCategory.name,
      omitCurrent: true
    });
    if (isServer) await breadCrumbsLoader;
    catalogHooksExecutors.categoryPageVisited(currentCategory);
  } catch (e) {
    console.error('Problem with setting Category initial data!', e);
  }
};

export default {
  components: {
    ChatBotIcon,
    PickupPointModalWindow,
    PickupPointPicker,
    LazyHydrate,
    ProductListing,
    SearchBar
  },
  mixins: [onBottomScroll, ScrollDirection],
  mounted () {
    const searchBar = this.$refs['search-bar-wrapper'];
    this.resizeObserver = new ResizeObserver(this.handleSearchBarResize);
    if (searchBar) {
      this.resizeObserver.observe(searchBar);
    }
    if (this.getCartItems.length) {
      syncUrlQuery(this.$store, this.$route, this.$router);
    }
    this.$on('scroll-direction-change', this.handleScrollDirectionChange);

    this.fetchCategoryList(config.partnerData.id)
  },
  beforeDestroy () {
    this.resizeObserver.disconnect();
  },
  data () {
    return {
      mobileFilters: false,
      defaultColumn: 4,
      loadingProducts: false,
      loading: true,
      showPickupPoints: false,
      searchBarVisible: true,
      searchBarFixed: false,
      resizeObserver: undefined,
      productsOffsetTop: 0,
      categoryList: [],
    };
  },
  serverPrefetch () {
    const { id: partnerId } = this.$ssrContext.partnerData;
    return this.fetchCategoryList(partnerId);
  },
  computed: {
    ...mapGetters({
      getCategoryProducts: 'category-next/getCategoryProducts',
      getCurrentCategory: 'category-next/getCurrentCategory',
      getCategoryProductsTotal: 'category-next/getCategoryProductsTotal',
      getAvailableFilters: 'category-next/getAvailableFilters',
      getCartItems: 'cart/getCartItems',
      getTotals: 'cart/getTotals',
      getPickupPoints: 'partner/getPickupPoints'
    }),
    isLazyHydrateEnabled () {
      return config.ssr.lazyHydrateFor.includes('category-next.products');
    },
    isCategoryEmpty () {
      return this.getCategoryProductsTotal === 0;
    }
  },
  async asyncData ({ store, route, router }) { // this is for SSR purposes to prefetch data - and it's always executed before parent component methods
    await composeInitialPageState(store, route, router, true);
  },
  async beforeRouteEnter (to, from, next) {
    if (isServer) {
      next();
      // SSR no need to invoke SW caching here
    } else if (!from.name) { // SSR but client side invocation, we need to cache products and invoke requests from asyncData for offline support
      next(async vm => {
        vm.loading = true;
        await composeInitialPageState(vm.$store, to, vm.$router, true);
        await vm.$store.dispatch('category-next/cacheProducts', { route: to }); // await here is because we must wait for the hydration
        vm.loading = false;
      });
    } else { // Pure CSR, with no initial category state
      next(async vm => {
        vm.loading = true;
        await vm.$store.dispatch('category-next/cacheProducts', { route: to });
        vm.loading = false;
      });
    }
  },
  methods: {
    fetchCategoryList (partnerId) {
      return new Promise(async(resolve, reject) => {
        try {
          const homepageQueryResponse = await this.$apollo.query({
            query: HOMEPAGE_QUERY,
            variables: {
              "filters": {
                "Partner": {
                  "Name": {
                    "eq": partnerId
                  }
                }
              }
            }
          });

          this.categoryList = homepageQueryResponse?.data?.homepages?.data[0]?.attributes?.Homepage
            .find((component) => {
              return component.__typename === 'ComponentHomepageSearchBar';
            })?.Category?.map(({ id, Link, Name }) => ({
              id,
              link: Link,
              name: Name,
            })) || [];
          resolve();
        } catch (error) {
          Logger.error('Cannot load data on category page: ' + error);
          reject();
        }
      });
    },
    handleSearchBarResize (e) {
      const height = e[0]?.contentRect.height;
      if (height) {
        this.productsOffsetTop = 28 + height;
      }
    },
    handleScrollDirectionChange (event) {
      const dir = event.direction;
      const scrollY = event.scrollY;
      if (scrollY > 400) {
        if (dir === 'down') {
          this.searchBarVisible = false;
        }
        if (dir === 'up') {
          this.searchBarVisible = true;
        }
      } else {
        this.searchBarVisible = true;
      }
    },
    applyPickupPointPickerFilters (filters) {
      const partnerFilters = this.$route.query[PARTNER_TYPE];
      const nonPickupPointPartnerIds = partnerFilters
        ? partnerFilters.filter(pfId => this.getPickupPoints.find(pickupPoint => +pfId !== +pickupPoint.partnerId))
        : [];
      const query = {
        ...this.$route.query,
        [PARTNER_TYPE]: [...nonPickupPointPartnerIds, ...filters.map(f => f.id.toString())]
      };
      this.$router.push({ query });
      this.showPickupPoints = false;
    },
    getPreselectedPartnerFilters () {
      const partnerFilters = this.$route.query[PARTNER_TYPE];
      if (partnerFilters) {
        const pickupPoints = this.getPickupPoints
          .filter(pickupPoint => partnerFilters.find(pfId => +pfId === +pickupPoint.partnerId)).map(pp => ({
            id: pp.partnerId,
            label: pp.partnerName,
            type: PARTNER_TYPE,
            attribute_code: PARTNER_TYPE
          }));
        return pickupPoints;
      }
      return [];
    },
    setShowPickupPoints (value) {
      this.showPickupPoints = value;
    },
    openFilters () {
      this.mobileFilters = true;
    },
    closeFilters () {
      this.mobileFilters = false;
    },
    columnChange (column) {
      this.defaultColumn = column;
    },
    async onBottomScroll () {
      if (this.loadingProducts) return;
      this.loadingProducts = true;
      try {
        await this.$store.dispatch('category-next/loadMoreCategoryProducts');
      } catch (e) {
        console.error('Problem with fetching more products', e);
      } finally {
        this.loadingProducts = false;
      }
    }

  },
  watch: {
    $route () {
      if (this.getCartItems.length) {
        composeInitialPageState(this.$store, this.$route, this.$router);
      }
    },
    getTotals () {
      if (this.getCartItems.length) {
        syncUrlQuery(this.$store, this.$route, this.$router);
      }
    }
  },
  metaInfo () {
    const { meta_title, meta_description, name } = this.getCurrentCategory;
    const meta = meta_description ? [
      { vmid: 'description', name: 'description', content: htmlDecode(meta_description) }
    ] : [];

    return {
      title: htmlDecode(meta_title || name),
      meta,
      link: [
        selfCanonical(this.$route)
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.h1 {
  font-size: 0;
  line-height: 0;
  opacity: 0;
  height: 0;
  visibility: hidden;
}

.btn {
  &__filter {
    min-width: 100px;
  }
}

.divider {
  width: calc(100vw - 8px);
  bottom: 20px;
  left: -36px;
}

.category-filters {
  width: 242px;
}

.mobile-filters {
  display: none;
  overflow: auto;
}

.mobile-filters-button {
  display: none;
}

.mobile-sorting {
  display: none;
}

.category-title {
  line-height: 65px;
}

.sorting {
  label {
    margin-right: 10px;
  }
}

@media (max-width: 64em) {
  .products-list {
    // max-width: 530px;
  }
}
@media (max-width: 777px) {
  .category-title {
    margin: 0;
    font-size: 36px;
    line-height: 40px;
  }

  .products-list {
    width: 100%;
    max-width: none;
  }

  .mobile-filters {
    display: block;
  }

  .mobile-filters-button {
    display: block;
    height: 45px;
  }

  .sorting {
    display: none;
  }

  .mobile-sorting {
    display: block;
  }

  .category-filters {
    display: none;
  }

  .product-listing {
    justify-content: center;;
  }
  .mobile-filters {
    position: fixed;
    background-color: #F2F2F2;
    z-index: 5;
    padding: 0 40px;
    left: 0;
    width: 100vw;
    height: 100vh;
    top: 0;
    box-sizing: border-box;
  }

  .mobile-filters-body {
    padding-top: 50px;
  }
}

@media (min-width: 574px) {
  .search-bar-fixed {
    width: 100%;
    background-color: white;
    position: fixed;
    padding-top: 84px;
    top: -550px;
    left: 0px;
    z-index: 2;
    transition: top 0.3s ease-in-out;

    &.search-bar-visible {
      top: -25px;
    }
  }
}

@media (max-width: 574px) {
  .products-list {
    padding-top: 0 !important;
  }
}

.close-container {
  left: 0;
}

.close {
  margin-left: auto;
}

.text-right {
  text-align: right;
}
</style>
<style lang='scss'>

.product-image {
  max-height: unset !important;
}
</style>
