export default {
  name: 'ScrollDirection',
  data () {
    return {
      isScrolling: false,
      lastScrollY: 0,
      lastScrollX: 0
    };
  },
  mounted () {
    window.addEventListener('wheel', this.handleScroll);
  },
  beforeDestroy () {
    window.removeEventListener('wheel', this.handleScroll);
  },
  methods: {
    handleScroll () {
      if (window.scrollY > this.lastScrollY) {
        this.$emit('scroll-direction-change',
          { direction: 'down', scrollY: window.scrollY });
        this.lastScrollY = window.scrollY;
      }
      if (window.scrollY < this.lastScrollY) {
        this.$emit('scroll-direction-change',
          { direction: 'up', scrollY: window.scrollY });
        this.lastScrollY = window.scrollY;
      }
      if (window.scrollX < this.lastScrollX) {
        this.$emit('scroll-direction-change',
          { direction: 'left', scrollY: window.scrollX });
        this.lastScrollX = window.scrollY;
      }
      if (window.scrollX > this.lastScrollX) {
        this.$emit('scroll-direction-change',
          { direction: 'right', scrollY: window.scrollX });
        this.lastScrollX = window.scrollY;
      }
    }
  }
};
