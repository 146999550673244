import { Route } from 'vue-router';
import config from 'config'

export const selfCanonical = (route: Route) => {
  const url = config.server.websiteUrl + route.path;
  return {
    rel: 'canonical',
    href: url
  }
}
