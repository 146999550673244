<template>
  <div class="pickup-point-picker">
    <div class="row pickup-point-picker-content relative">
      <div class="pickup-point-list col-xs-12 col-md-5 pr10"
           :class="{'hidden-xs':mapVisible}"
      >
        <pickup-point-list
          :show-dropdown="false"
          @pickup-point-select="handlePickupPointSelected"
          :pickup-points="pickupPoints"
        />
      </div>
      <div class="col-xs-12 pl10 col-md-7 relative pickup-point-map-container"
           :class="{'hidden-xs':!mapVisible}"
      >
        <pickup-point-map
          @pickup-point-select="handlePickupPointSelected"
          :selected-pickup-point="selectedPickupPoint"
          :pickup-points="pickupPoints"
        />
      </div>
    </div>
    <div class="row w-100">
      <div class="col-xs-12  button-panel">
        <button-full @click.native="setMapVisible(true)"
                     class="bottom-button"
                     :class="{'bottom-button--white':!mapVisible}"
        >
          {{ $t('Map') }}
        </button-full>
        <button-full @click.native="setMapVisible(false)"
                     class="bottom-button"
                     :class="{'bottom-button--white':mapVisible}"
        >
          {{ $t('List') }}
        </button-full>
      </div>
      <div class="selected-pickup-points_label col-xs-12 mb10 px10">
        <span>{{ $t('Selected pickup points') }}:</span>
      </div>
      <div class="col-xs-12">
        <div class="selected-pickup-points px5 w-100"
             :class="{'selected-pickup-points--small':addressBarVisible}"
        >
          <template
            v-for="(filter, filterIndex) in selectedFilters"
          >
            <search-bar-filter-selector
              class="size-select mr10 mb10 block"
              :code="filterIndex"
              :key="filter.type +''+filterIndex"
              :variant="filter"
              :selected-filters="selectedFilters"
              @change="handleFilterSelectorClicked(filter, $event)"
            />
          </template>
        </div>
        <div class="flex justify-content-center w-100">
          <button-full @click.native="$emit('filter',selectedFilters)" :disabled="!selectedFilters.length" class="w146"
                       :style="filterButtonCustomStyle"
          >
            {{ $t('Apply filter') }}
          </button-full>
        </div>
        <div class="py50" />
      </div>
    </div>
  </div>
</template>
<script>
import ButtonFull from '../../../theme/ButtonFull';
import { PARTNER_TYPE } from '../../../../../../constants/FilterVariantTypes';
import SearchBarFilterSelector from '../../../theme/anyrent/SearchBar/SearchBarFilterSelector';
import PickupPointList from './PickupPointList';
import PickupPointMap from './PickupPointMap';

export default {
  name: 'PickupPointPicker',
  components: {
    SearchBarFilterSelector,
    PickupPointList,
    ButtonFull,
    PickupPointMap
  },
  props: {
    preselectedPartnerFilters: {
      type: Array,
      required: false,
      default: []
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    pickupPoints: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      mapVisible: true,
      addressBarVisible: true,
      selectedFilters: [],
      filterButtonCustomStyle: { 'padding-bottom': 0 },
      selectedPickupPoint: undefined
    };
  },
  beforeMount () {
    this.$on('address-bar-changed', this.handleAddressBarChanged);
  },
  mounted () {
    this.selectedFilters = this.preselectedPartnerFilters;
  },
  computed: {
    getPickupPointFilters () {
      if (!this.pickupPoints) {
        return [];
      }
      return this.pickupPoints.map(pp => ({
        id: pp.partnerId,
        label: pp.partnerName,
        type: PARTNER_TYPE,
        attribute_code: PARTNER_TYPE
      }));
    }
  },
  methods: {
    setMapVisible (visible) {
      this.mapVisible = visible;
    },
    handleAddressBarChanged (event) {
      this.addressBarVisible = event.visible;
    },
    handlePickupPointSelected (pickupPoint) {
      this.selectedPickupPoint = pickupPoint
      const filter = this.getPickupPointFilters
        .filter(filter => !this.selectedFilters.find(sf => sf.id === filter.id))
        .find(filter => filter.id === pickupPoint.partnerId);

      if (filter) {
        this.selectedFilters.push(filter);
      }
    },
    handleFilterSelectorClicked (filter, event) {
      this.selectedFilters = this.selectedFilters.filter(pp => pp !== filter);
    }
  }
};

</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-silver: color(silver);

.pickup-point-list {
  height: 400px;
  overflow: scroll;
}

.pickup-point-picker {
  height: calc(100vh - 50px);
  overflow: scroll;
}

.pickup-point-map-container {
  height: 400px;
  width: 100%;
}

.button-panel {
  display: none;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.bottom-button {
  min-width: unset;
  width: unset;
  padding-left: 0;
  padding-right: 0;
}

.bottom-button::v-deep .wrap {
  width: 100px;
  color: white;
}

.bottom-button--white::v-deep .wrap {
  background-color: $color-silver;
}

.bottom-button::v-deep .wrap {
  width: 100px;
  color: white;
}

.w146::v-deep .wrap {
  width: 146px;
}

@media (max-width: 768px) {
  .button-panel {
    display: flex;
  }
}

@media (max-width: 572px) {
  .button-panel {
    display: flex;
  }
  .selected-pickup-points_label {
    padding-left: 20px;
  }
  .pickup-point-list {
    height: initial;
    overflow: initial;
  }
}

</style>
