<template>
  <button
    :class="{'active': isActive}"
    class="bg-cl-primary brdr-square h5 cl-tertiary generic-selector"
    @click="$emit('change', variant)"
    :aria-label="$t('Select ' + variant.label)"
  >
    {{ variant.label }}
  </button>
</template>

<script>
import filterMixin from 'theme/mixins/filterMixin.ts'

export default {
  mixins: [filterMixin]
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-active: color(secondary);
  $color-disabled: color(secondary, $colors-border);
  $color-accent: color(partner);
  $color-silver: color(primary, $colors-border);

  .generic-selector {
    height: 40px;
    padding-left: 8px;
    padding-right: 8px;
    min-width: 50px;
    display: inline;
    border: none;
    outline: 1px solid $color-silver;

    &:hover,
    &:focus {
      outline-color: $color-accent;
      outline-width: 2px;
      transition: 50ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &.active {
      outline-color: $color-accent;
      outline-width: 2px;
      color: $color-accent;
      transition: 20ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &:disabled {
      outline-color: $color-disabled;
      color: $color-disabled;
      cursor: not-allowed;

      &:hover,
      &:after {
        outline-width: 1px;
      }
    }
  }
</style>
