<template>
  <svg
    class="chat-bot-icon"
    @click="$store.dispatch('ui/setChatBot', true)"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 52"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
  >
    <g transform="matrix(1,0,0,1,-561.452,-262.559)">
      <g transform="matrix(1,0,0,1,607.712,290.354)">
        <g transform="matrix(1,0,0,1,-23.5,-23.5)">
          <path
            d="M16.894,1.356C18.46,0.925 20.078,0.658 21.723,0.567L23.077,0.567C26.648,0.557 30.17,1.393 33.358,3.002C37.178,4.912 40.391,7.846 42.637,11.479C44.883,15.111 46.074,19.297 46.076,23.568C46.086,27.139 45.252,30.661 43.641,33.849C43.641,33.849 47.303,43.184 45.591,44.897C43.876,46.611 33.358,44.131 33.358,44.131C30.17,45.743 26.648,46.576 23.077,46.567C18.806,46.565 14.62,45.374 10.988,43.128C10.297,42.701 9.631,42.238 8.993,41.744C11.052,41.165 13.03,40.299 14.865,39.164C18.499,36.917 21.433,33.705 23.342,29.884C24.951,26.697 25.787,23.175 25.777,19.603L25.777,18.249C25.466,12.609 23.085,7.283 19.091,3.289C18.397,2.595 17.663,1.95 16.894,1.356Z"
            style="fill:rgb(147,147,147);fill-rule:nonzero;"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,584.952,286.059)">
        <g transform="matrix(1,0,0,1,-23.5,-23.5)">
          <path
            class="colorizable"
            d="M46.076,23.568C46.086,27.139 45.25,30.661 43.641,33.849C41.731,37.669 38.797,40.882 35.164,43.128C31.532,45.374 27.346,46.565 23.075,46.567C19.504,46.576 15.982,45.743 12.794,44.131C12.794,44.131 2.276,46.611 0.561,44.897C-1.151,43.184 2.511,33.849 2.511,33.849C0.9,30.661 0.066,27.139 0.076,23.568C0.078,19.297 1.269,15.111 3.515,11.479C5.761,7.846 8.974,4.912 12.794,3.002C15.982,1.393 19.504,0.557 23.075,0.567L24.429,0.567C30.069,0.879 35.396,3.259 39.39,7.253C43.383,11.247 45.764,16.574 46.076,22.214L46.076,23.568Z"
          />
          <path
            d="M17.971,17.448C18.364,16.332 19.14,15.391 20.159,14.793C21.179,14.193 22.378,13.973 23.545,14.173C24.71,14.374 25.767,14.98 26.529,15.884C27.291,16.79 27.708,17.935 27.706,19.118L27.706,19.122C27.706,19.854 27.318,20.429 26.851,20.971C26.12,21.818 25.169,22.568 24.312,23.353C22.364,25.14 21.22,28.425 21.22,28.425C20.936,29.463 21.004,31.423 22.041,31.707C23.079,31.99 24.573,31.52 24.858,30.484C24.858,30.484 25.604,27.454 26.946,26.225C27.914,25.339 28.976,24.474 29.802,23.515C30.891,22.251 31.602,20.828 31.602,19.122C31.606,17.02 30.864,14.984 29.51,13.375C28.156,11.767 26.276,10.688 24.203,10.333C22.13,9.977 19.997,10.366 18.185,11.432C16.371,12.498 14.994,14.171 14.296,16.157C13.938,17.17 14.472,18.284 15.487,18.641C16.502,18.997 17.614,18.463 17.971,17.448Z"
            style="fill:white;"
          />
          <path
            d="M23.244,37.911C24.33,37.911 25.21,37.02 25.21,35.922C25.21,34.824 24.33,33.933 23.244,33.933C22.158,33.933 21.278,34.824 21.278,35.922C21.278,37.02 22.158,37.911 23.244,37.911Z"
            style="fill:rgb(255,254,254);fill-rule:nonzero;"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'ChatBotIcon'
};
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-store-type: color(partner);

.chat-bot-icon {
  height: fit-content;
  position: fixed;
  top: 80%;
  right: 20px;
  cursor: pointer;
  z-index: 3;
  opacity: 0.8;
  width: 50px;

  transition: opacity 0.3s ease;
  animation: double-bump 10s ease-in-out infinite;

  .colorizable {
    fill: rgb(147, 147, 147);
    stroke: $color-store-type;
    stroke-width: 2;
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    transition: fill 0.3s ease-in 0.2s, opacity 0.3s ease;
  }

  &:hover {
    opacity: 1;
    animation: none; // Disable bump animation during hover
    .colorizable {
      animation: draw 0.8s ease forwards; // Stroke animation
      fill: $color-store-type; // Change fill to store color
    }
  }

  &:not(:hover) {
    .colorizable {
      transition: fill 0.3s ease, stroke-dashoffset 0.3s ease, opacity 0.3s ease;
      stroke-dashoffset: 300;
      fill: rgb(147, 147, 147);
    }
  }
}

@keyframes double-bump {
  0%, 95%, 100% {
    transform: translateY(0);
  }
  2% {
    transform: translateY(-14px) scale(1.1);
  }
  4% {
    transform: translateY(0) scale(1);
  }
  6% {
    transform: translateY(-10px) scale(1.08);
  }
  8% {
    transform: translateY(0) scale(1);
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@media (max-width: 575px) {
  .chat-bot-icon {
    top: 85%;
    right: 10px;
  }
}
</style>
