<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <div class="filtering">
          <div
            v-if="availableFilters.partner"
            class="filter_tabs mb15"
          >
            <div class="flex align-items-center">
              <span
                class="filters-texts"
                :class="{ active_tab: activeTab === 'partner' && !isFilterLockedOnPartner(availableFilters.partner)}"
                @click="handleFilterClicked(availableFilters.partner,'partner')"
                @mouseover="handleFilterMouseOver(availableFilters.partner,'partner')"
              > {{ $t('Pickup point') }}</span>
              <i v-if="!getCartItems.length" @click="$emit('partnerInfoClick')" class="material-icons info-icon ml5">info_outline</i>
              <span class="separator m5" v-if="Object.values(availableFilters).length > 1">|</span>
            </div>
          </div>

          <div
            class="filter_tabs pr15 mb15"
            v-for="(filter, filterIndex, index) in availableFilters"
            v-if="filterIndex !== 'partner'"
            :key="'c'+index"
          >
            <span
              class="filters-texts"
              :class="{ active_tab: activeTab === filterIndex && !isFilterLockedOnPartner(filter)}"
              @click="handleFilterClicked(filter,filterIndex)"
              @mouseover="handleFilterMouseOver(filter,filterIndex)"
            >
              <!-- {{ $t(filterIndex + '_filter') }} -->
              {{ filter.default_label }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row"
         v-for="(filter, filterIndex, index) in availableFilters"
         :key="index"
         v-if="activeTab === filterIndex "
    >
      <div class="col-xs-12">
        <div class="filtering pr15 ">
          <template v-for="(option, index) in filter">
            <search-bar-filter-selector
              class="size-select mb10 block"
              :code="filterIndex"
              :key="index"
              :variant="option"
              :defaultlabel="filter.default_label"
              :selected-filters="getSearchBarFilters"
              :is-disabled="isFilterLockedOnPartner(filter)"
              @change="handleFilterSelectorClicked(filter,$event)"
            />
            <span class="separator m5" :key="option.id" v-if="index !== (filter.length -1)"> | </span>
          </template>
        </div>
      </div>
    </div>
    <div class=" x " v-if="getSearchBarFilters.length">
      <div class="follow selected-filters pr5">
        {{ $t('Selected filters') }}:
      </div>
      <div class="follow">
        <div class="row">
          <div class="col-xs">
            <div class="filtering pl0">
              <template v-for="(filter, filterIndex) in getSearchBarFilters">
                <search-bar-filter-selector
                  class="size-select mr10 mb10 block"
                  :code="filterIndex"
                  :key="filter.type +''+filterIndex"
                  :variant="filter"
                  :selected-filters="getSearchBarFilters"
                  :is-disabled="isFilterLockedOnPartner(filter)"
                  :defaultlabel="getFilterLabelByType(filter.type)"
                  type="button"
                  @change="handleFilterSelectorClicked(filter, $event)"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pickBy from 'lodash-es/pickBy'
import find from 'lodash-es/find'
import SearchBarFilterSelector from './SearchBarFilterSelector'
import { mapGetters } from 'vuex'
import { notifications } from '@vue-storefront/core/modules/cart/helpers';
import { AVAILABILITY_TYPE, PARTNER_TYPE, SEARCH_STRING_TYPE } from '../../../../../../constants/FilterVariantTypes';

export default {
  name: 'SearchBarFilters',
  components: {
    SearchBarFilterSelector
  },
  props: {
    selectedCategory: {},
    filters: {},
    currentFilters: {}
  },
  data () {
    return {
      activeTab: 'price'
    }
  },
  computed: {
    ...mapGetters({
      getpreloadedDefaults: 'attribute/getFullPreloadedDefaults',
      getCartItems: 'cart/getCartItems',
      getSource: 'cart/getSource'
    }),

    getSearchBarFilters () {
      return Object.values(this.currentFilters)
        .filter(f => Array.isArray(f))
        .reduce((acc, f) => [...acc, ...f], [])
        .filter(f => ![AVAILABILITY_TYPE, SEARCH_STRING_TYPE].find(type => type === f.type));
    },
    availableFilters () {
      // copy the filters
      const modifiedFilters = pickBy(this.filters, (filter, filterType) => { return (filter.length && !this.$store.getters['category-next/getSystemFilterNames'].includes(filterType)) })
      const x = this.addDefaultLabel(modifiedFilters)
      return x
    }
  },
  methods: {
    isFilterLockedOnPartner (filter) {
      return !!(this.getCartItems.length && ((filter.type && filter.type === PARTNER_TYPE) || (filter.length && filter[0].type === 'partner')));
    },
    async changeFilter (filter) {
      await this.$store.dispatch('category-next/switchSearchFilters', [filter]);
    },
    getFilterLabelByType (type) {
      const att = this.getpreloadedDefaults.find(a => a.attribute_code === type);
      if (att) {
        return att.default_frontend_label;
      }
      return '*';
    },
    addDefaultLabel (newFilters) {
      for (const [key, value] of Object.entries(newFilters)) {
        const preloadedFilter = find(this.getpreloadedDefaults, { 'attribute_code': key });

        if (preloadedFilter) {
          if (preloadedFilter.default_frontend_label) {
            value.default_label = preloadedFilter.default_frontend_label
          } else {
            value.default_label = key
          }
        } else {
          value.default_label = key
        }
      }
      return newFilters
    },
    handleFilterMouseOver (filter, filterIndex) {
      this.filterTab(filterIndex);
    },
    handleFilterSelectorClicked (filter, event) {
      if (this.isFilterLockedOnPartner(filter)) {
        const message = this.$t('Products are filtered on partner {partnerName}. Finish the order to see all products.')
          .replace('{partnerName}', this.getSource ? this.getSource.name : '');
        const notification = notifications.createNotification({ type: 'warning', message: message });
        this.$store.dispatch('notification/spawnNotification', notification, { root: true });
      } else {
        this.changeFilter(event, filter);
      }
    },
    handleFilterClicked (filter, filterIndex) {
      if (this.isFilterLockedOnPartner(filter)) {
        const message = this.$t('Products are filtered on partner {partnerName}. Finish the order to see all products.')
          .replace('{partnerName}', this.getSource ? this.getSource.name : '');
        const notification = notifications.createNotification({ type: 'warning', message: message });
        this.$store.dispatch('notification/spawnNotification', notification, { root: true });
      }
      this.filterTab(filterIndex);
    },
    filterTab (type) {
      this.activeTab = type
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
@import '~theme/css/animations/transitions';
$color-icon-hover: color(secondary, $colors-background);
$color-separator: color(anyrent);
$color-suva-gray: color(suva-gray);
$color-gray: color(gray);

.filtering {
  padding-left: 5px;
  padding-right: 5px;
  font-family: 'TTCommons-Light', sans-serif;
  font-size:18px;
}
.align-items-center {
  align-items: center;
}
.filter_tabs{
  display: inline-block;
  border-bottom: 0px;
  cursor: pointer;
}
.subcategories{
  display:inline-block;
  font-size: 14px;
  &:hover {
    color:color(anyrent, $colors-theme);
  }
}
.subcategories {
  font-family: 'TTCommons-DemiBold', sans-serif;
}
.active_tab {
  border-bottom: 1px solid color(anyrent, $colors-theme);
}
.filters-texts:hover{
  border-bottom: 1px solid color(anyrent, $colors-theme);
  transition: 0.5s border-bottom $motion-main;
}
.separator {
  color: $color-separator;
}
.selected-filters {
  font-family: 'TTCommons-Light', sans-serif;
  font-size: 16px;
  display: inline-block;
}

.category-filter--disabled{
  color: $color-suva-gray;
  border-bottom: none;
}

.category-filter--disabled:hover {
  color: $color-suva-gray;
  border-bottom: none;
}

.material-icons.info-icon {
  color: $color-gray;
  font-size: 22px;
}

.follow {
  display: inline-block;
}

.ml5 {
  margin-left: 5px;
}
</style>
