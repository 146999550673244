<template>
  <button
    v-if="variant.type ==='price'"
    :class="{'button-style': isButtonType, 'color-selected': isSelected(variant) && type ==='text'}"
    class="bg-cl-primary brdr-1 brdr-cl-primary brdr-square h5 generic-selector"
    @click="$emit('change', variant)"
    :aria-label="$t('Select ' + variant.label)"
  >
    <span class="button-text"
          :class="{'redcross': isButtonType}"
    >
      {{ priceLabel }}
    </span>
  </button>
  <button
    v-else
    :class="{'button-style': isButtonType, 'disabled':isDisabled,'color-selected':isSelected(variant) && type ==='text'}"
    class="bg-cl-primary brdr-1 brdr-cl-primary brdr-square h5 generic-selector"
    @click="$emit('change', variant)"
    :aria-label="$t('Select ' + variant.label)"
  >
    <span class="button-text"
          :class="{'redcross': isButtonType}"
    >
      <!-- <span v-if="isActive"> {{ $t(variant.type+'_filter') }} | &nbsp</span> -->
      <span v-if="isButtonType"> {{ defaultlabel === ' * ' ? '' : `${defaultlabel} |` }}  &nbsp;</span>
      {{ variant.label }}
    </span>
  </button>
</template>

<script>
import filterMixin from 'theme/mixins/filterMixin.ts'

export default {
  mixins: [filterMixin],
  data () {
    return {};
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isSelected (variant) {
      return this.selectedFilters.find(f => f.id === variant.id);
    }
  },
  computed: {
    isButtonType () {
      return this.isActive && this.type === 'button'
    },
    priceLabel () {
      const from = this.variant.from
      const to = this.variant.to
      let label = ''

      if (from === 0) {
        label = `< ${to},- Kč / den`
      } else if (to === undefined) {
        label = `> ${from},- Kč / den`
      } else {
        label = `${from} - ${to},- Kč / den`
      }
      return label
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-active: color(secondary);
  $color-disabled: color(secondary, $colors-border);
  $color-suva-gray: color(suva-gray);
  $color-anyrent: color(anyrent);

  button {
    color: #646464;
  }
  .color-selected {
    color: $color-anyrent;
  }
  .generic-selector {
    height: 30px;
    padding-left: 0px;
    padding-right: 0px;
    display: inline;
    border: 0px;
    border-radius: 20px;
    font-family: 'TTCommons-Light';

    &:hover {
      color:color(anyrent, $colors-theme);
    }
    &.button-style{
      border-color: #828282;
      border-width: 2px;
      background-color: #f0eeec;
      padding-right: 10px;
      padding-left: 10px;
      &:hover{
        background-color: color(matterhorn);
      }
    }

    &.disabled {
      border-color: $color-suva-gray;
      color: $color-suva-gray;
      cursor: not-allowed;

      &:hover,
      &:after {
        border-width: 1px;
      }
    }
  }
  span.button-text {
    display: inline-block;
  }
  span.button-text.redcross {
    background-image: url("/assets/redcross.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 10px;
    padding-right: 15px;
  }
</style>
