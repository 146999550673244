<template>
  <svg :class="`${direction}`" width="8" height="15" viewBox="0 0 8 15" :fill="fill">
    <path d="M7 13.9L1 7.60005L7 1.30005" :stroke="stroke" :stroke-width="strokeWidth" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'ArrowIcon',
  props: {
    direction: {
      type: String,
      default: 'left'
    },
    fill: {
      type: String,
      default: 'none'
    },
    stroke: {
      type: String,
      default: 'none'
    },
    strokeWidth: {
      type: String,
      default: '2'
    }
  }
};
</script>
<style>
.up {
  transform: rotate(90deg);
  transform-origin: 50% 50%;
}
.right{
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}
.down {
  transform: rotate(270deg);
  transform-origin: 50% 50%;
}

</style>
