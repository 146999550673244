<template>
  <div class="window-container">
    <div @click="$emit('close')" class="overlay" />
    <div class="content">
      <div class="top-panel">
        <h2 class="cl-accent" v-if="header">
          {{ header }}
        </h2>
        <cross-button class="cross-button" @click="$emit('close')" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import CrossButton from '../../CrossButton';

export default {
  name: 'PickupPointOverlay',
  components: { CrossButton },
  props: {
    header: {
      type: String
    }
  },
  mounted () {
    this.$store.dispatch('ui/setAppScrollLock', true);
  },
  beforeDestroy () {
    this.$store.dispatch('ui/setAppScrollLock', false);
  }
};
</script>

<style lang="scss" scoped>
.overlay {
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
}

.window-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  top: 0;
  left: 0;
}

.content {
  background-color: white;
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  width: 800px;
  height: 700px;
  font-family: ttcommons-regular, sans-serif;
}

.cross-button {
  width: 25px;
}

.top-panel {
  padding: 5px 10px;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .cross-button {
    margin-right: 20px
  }
  .content {
    width: 100vw;
  }
}

@media (max-width: 572px) {
  .cross-button {
    margin-right: 20px
  }

  .top-panel {
    height: 40px;
  }

  .content {
    width: 100vw;
    height: 100vh;
    padding: 0;
  }

  .overlay {
    display: none;
  }
}

</style>
